import dynamic from "next/dynamic";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import LayoutMain from "@/layouts/Main";
import LayoutCoreProvider from "@/layouts/CoreProvider";
import LazyLoading from "@/components/LazyLoading";

import type { NextPageWithLayout } from "@/pages/_app";
import type { GetStaticProps } from "next";

const View404 = dynamic(() => import("@/views/404"), {
  suspense: true,
  ssr: false,
  loading: () => <LazyLoading />,
});

const _404: NextPageWithLayout = () => {
  return <View404 />;
};

_404.getLayout = (page, _, { translation }) => {
  const { t } = translation;

  return (
    <LayoutCoreProvider
      headParams={{
        title: t("Not found")!,
      }}
    >
      <LayoutMain>{page}</LayoutMain>
    </LayoutCoreProvider>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale || "")),
    },
  };
};

export default _404;
